import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { useUpdateSettingMutation } from '@/entities/admin-app/user/api';
import { useActions } from '@/hooks/redux/action';
import { useAppSelector } from '@/hooks/redux/redux';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { Button, Checkbox, Select, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconChevronDown } from '@tabler/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ProfileTimeZones = () => {
  const { t } = useTranslation();
  const { Timezones } = useAppSelector((state) => state.commonSettings.TimezoneSettings);
  const { Settings } = useAppSelector((state) => state.userInfo);
  const Timezone = Settings?.Timezone;
  const [updateInfo, { isLoading }] = useUpdateSettingMutation();
  const { setTimezoneSettings } = useActions();
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      checkbox: Timezone?.SecondEnabled,
      mainTimezone: String(Timezone?.Id) ?? '0',
      secondTimezone: String(Timezone?.SecondId) ?? '0'
    }
  });

  const data = useMemo(() => {
    return Object.values(Timezones).sort((a, b) => a.utcOffsetMinutes - b.utcOffsetMinutes);
  }, [Timezones]);

  const handleSubmit = async () => {
    const data = form.getValues();
    if (!Settings) return;
    const res = await updateInfo({
      ...Settings,
      Timezone: {
        ...Settings.Timezone,
        Id: (Number(data.mainTimezone) as number) ?? 0,
        SecondEnabled: (data.checkbox as boolean) ?? false,
        SecondId: (Number(data.secondTimezone) as number) ?? 0
      }
    });
    if ('error' in res) {
      const codeError = extractErrorCode(res.error);
      showNotification({
        title: generateErrorMessage(codeError),
        message: '',
        autoClose: true,
        color: 'red',
        style: { zIndex: 1003 }
      });
    } else {
      setTimezoneSettings({
        ...Settings?.Timezone,
        Id: Number(data.mainTimezone) ?? 0,
        SecondEnabled: data.checkbox ?? false,
        SecondId: Number(data.secondTimezone) ?? 0
      });
      showNotification({
        title: t('saved'),
        message: '',
        autoClose: true,
        color: 'green',
        style: { zIndex: 1003 }
      });
    }
    form.resetDirty();
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SectionTemplate title={t('profile.timezones')}>
        <Checkbox
          styles={{ label: { cursor: 'pointer' }, labelWrapper: { width: 'auto !important' } }}
          fz="12px"
          label={t('profile.timezoneCheckboxLabel')}
          mb={12}
          {...form.getInputProps('checkbox', { type: 'checkbox' })}
        />

        <Select
          w="100%"
          maw={352}
          styles={{
            label: { color: '#667085', fontSize: 12 }
          }}
          radius="md"
          label={t('profile.timeZone')}
          placeholder={t('profile.selectTimeZone')}
          data={data}
          value={form.getInputProps('mainTimezone').value}
          mb={25}
          rightSection={<IconChevronDown stroke={1.5} />}
          searchable
          {...form.getInputProps('mainTimezone')}
        />

        <Text mb={24} w="90%" fz={14} c="#667085">
          {t('profile.timezoneInfo')}
        </Text>

        {form.getInputProps('checkbox').value && (
          <Select
            w="100%"
            maw={352}
            styles={{
              label: { color: '#667085', fontSize: 12 }
            }}
            radius="md"
            label={t('profile.additionalTimezone')}
            placeholder={t('profile.selectTimeZone')}
            data={data}
            value={form.getInputProps('secondTimezone').value}
            mb={25}
            rightSection={<IconChevronDown stroke={1.5} />}
            searchable
            {...form.getInputProps('secondTimezone')}
          />
        )}

        <Button w={200} loading={isLoading} h={36} type="submit" disabled={!form.isDirty()}>
          {t('save')}
        </Button>
      </SectionTemplate>
    </form>
  );
};

export default ProfileTimeZones;
