import { useTranslation } from 'react-i18next';
import { CardContainer } from '../../cardContainer';
import { IconCheck, IconExclamationCircle, IconWaveSawTool, IconX } from '@tabler/icons';
import { ItemProps, ListItem } from '../../listItem';
import { Box, Text, Stack, Flex, ThemeIcon } from '@mantine/core';
import { useStyles } from './styles';
import { FC, useEffect, useState } from 'react';
import { useGetServicesQuery } from '@/entities/admin-app/user/api';
import { HealthServiceType } from '@/entities/admin-app/user';
import { Skeleton } from '@mantine/core';

interface IServicesSectionProps {
  filialId?: number;
}

export const ServicesSection: FC<IServicesSectionProps> = ({ filialId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { data, isLoading, isError } = useGetServicesQuery(filialId ?? 0);
  const [servicesList, setServicesList] = useState<ItemProps[]>([]);

  useEffect(() => {
    if (data) {
      const transformedArray = data.map((item) => {
        const serviceName = t(`filials.services.${HealthServiceType[item.Type]}`);
        return {
          name: item.Name ? `${serviceName} ${item.Name}` : serviceName,
          valid: item.Health
        };
      });
      setServicesList(transformedArray);
    }
  }, [data]);

  const allServicesValid = servicesList.every((item) => item.valid);
  const statusColor = allServicesValid ? '#37B24D' : '#F03E3E';

  const statusText = allServicesValid
    ? t('filials.servicesStatusSuccess')
    : t('filials.servicesStatusError');

  return (
    <CardContainer sectionName={t('filials.availableServices')} Icon={IconWaveSawTool}>
      {isLoading ? (
        <Box w="100%">
          <Skeleton height={41} mb="sm" />
          {Array(8)
            .fill(0)
            .map((_, index) => (
              <Flex justify="space-between" align="center" mb="xs" key={index}>
                <Skeleton height={20} width="70%" radius="xl" />
                <Skeleton height={20} width={20} circle />
              </Flex>
            ))}
        </Box>
      ) : isError ? (
        <Box className={classes.errorContainer}>
          <IconExclamationCircle size="26" stroke="#848E98" />
          <Text className={classes.errorText}>{t('avatar.error')}</Text>
        </Box>
      ) : (
        <>
          <Box
            className={classes.statusBox}
            sx={{
              backgroundColor: allServicesValid ? '#D3F9D8' : '#FFE3E3'
            }}
          >
            <Flex align="center" gap="10px">
              {allServicesValid ? (
                <IconCheck color="#69DB7C" stroke={3} />
              ) : (
                <ThemeIcon mr="6px" radius="xl" color="#FA5252" size="25px">
                  <IconX size="13" stroke="4.5" />
                </ThemeIcon>
              )}
              <Text fz="sm" c={statusColor}>
                {statusText}
              </Text>
            </Flex>
          </Box>

          <Stack gap="7px" sx={{ width: '100%' }} mt="16px">
            <ListItem items={servicesList} withCheck={true} />
          </Stack>
        </>
      )}
    </CardContainer>
  );
};
